import { Component, Vue } from 'vue-property-decorator';
import { getComponentProps } from '@/utils/vueUtils';
import TextHeaderComponent from '@/components/TextHeader/TextHeader.vue';
import { TextHeader } from '@/common/interfaces/TextHeader';

const TextHeaderModuleProps = Vue.extend({
  props: getComponentProps<TextHeader>({
    Title: 'Expedition Stories',
    Text:
      'Our fleet navigates the world in search of adventure. These are the stories they bring back…',
    Color: 'black',
  }),
});

@Component({
  components: {
    TextHeaderComponent,
  },
})
export default class TextHeaderModule extends TextHeaderModuleProps {}
