import { Component, Vue } from 'vue-property-decorator';

import { TextHeaderBlockViewModel } from './TextHeader.types';
import { getComponentProps } from '@/utils/vueUtils';

const TextHeaderProps = Vue.extend({
  props: getComponentProps<TextHeaderBlockViewModel>({
    Title: '',
    Text: '',
    Color: '',
  }),
});

@Component({
  components: {},
})
export default class TextHeader extends TextHeaderProps {
  get ColorFromProps() {
    return 'text-header--color-' + this.p.Color;
  }
}
